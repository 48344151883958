<template>
	<v-container
			fluid
			fill-height
	>
		<v-img id="bg"></v-img>

		<v-dialog
			v-model="show"
			max-width="400"
			persistent
			hide-overlay
		>
			<v-card class="elevation-12">
				<v-card-title>
					Login
					<v-spacer />
					<span class="subtitle-2">v{{ env.VUE_APP_VERSION }}</span>
				</v-card-title>

				<v-divider />

				<ValidationObserver
					ref="credentials"
					@submit.prevent="submitLogin"
				>
					<form autocomplete="off">
						<v-card-text>

							<v-row>
								<v-col>
									<VTextFieldValidation
										autofocus
										rules="required"
										v-model="username"
										label="Username"
									/>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<VTextFieldValidation
										rules="required"
										v-model="password"
										label="Password"
										type="password"
									/>

									<VTextFieldValidation
										rules="required"
										v-model="password"
										hidden
									/>
								</v-col>
							</v-row>

						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<Button
								type="submit"
								:loading="loading"
								:disabled="loading"
							>Login</Button>
						</v-card-actions>
					</form>
				</ValidationObserver>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	import VTextFieldValidation from '@/components/validation/VTextField.vue'
	import { mapActions } from 'vuex'

	export default {
		components: {
			VTextFieldValidation
		},
		props: [`authenticated`],
		name: `Login`,
		data() {
			return {
				show: true,
				env: process.env,
				username: ``,
				password: ``,
				loading: false,
			}
		},
		methods: {
			...mapActions([`setAdmin`]),
			submitLogin() {
				this.$refs.credentials.validate().then(valid => {
					if (!valid) return
					this.loading = true

					this.$httpInt.post(`/v2/auth/login`, {
							account: this.username,
							password: this.password,
						})
						.then(() => this.$router.push({ name: `LoginCode` }))
						.finally(() => this.loading = false)
				})
			},
		},
		mounted() {
			this.$httpInt.get(`/v2/sanctum/csrf-cookie`)
		}
	}
</script>

<style scoped>
	#bg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-size: cover !important;
		background: grey url('../assets/wdht-background.jpg') no-repeat center center;
	}
</style>